<template>
  <div class="success">
    <div class="tp" v-if='type==1'>
      <img src="../../assets/成功success.png" alt="">
    </div>
    <div class="tps" v-if='type==0'>
      <img src="../../assets/成功success.png" alt="">
    </div>
    <p v-if='type==1'>报名成功</p>
    <p v-if='type==0'>参团成功</p>
    <div class="btn" @click='download'>
      查看课程
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type:''
    };
  },
  mounted(){
    let currentQuery = this.$route.query;
    this.type=currentQuery.type
    this.$nextTick(() => {

    });
  },
  methods:{
    download() {
        this.$router.push({ path:'/download'})
    }
  }
};
</script>
<style   lang="less" scoped>
.tp {
  width: 2.35rem;
    height: 2.35rem;
    position: absolute;
    left: 50%;
    // top: 15%;
    transform: translate(-50%, 0%);
}

.success{
  margin-top: 1.08rem;
}
  img {
    width: 100%;
    height: 100%;
  }
.tps {
  width: 2.35rem;
    height: 2.35rem;
    position: absolute;
    left: 50%;
    // top: 15%;
    transform: translate(-50%, 0%);

  img {
    width: 100%;
    height: 100%;
  }
}
p {
  width: 100%;
  text-align: center;
  font-size: .4rem;
  color: #2B2B2B;
  font-weight: 600;
  padding-top:2.88rem;
}
.btn {
  width: 4.27rem;
    height: 1.07rem;
    background: #2775FF;
    border-radius: 0.13rem 0.13rem 0.13rem 0.13rem;
    opacity: 1;
    font-size: 0.4rem;
    font-weight: normal;
    color: #FFFFFF;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  margin-top:.8rem;
  line-height: 1.07rem;
  text-align: center;
}

</style>